import { Component, OnInit, ElementRef, Renderer2, ViewChild, AfterViewInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../services/common.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, concat } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { ChangeDetectorRef } from '@angular/core';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatMenuTrigger } from "@angular/material/menu";

export class Filter {
  constructor(public name: string) { }
}
@Component({
  selector: "app-case360",
  templateUrl: "./case360.component.html",
  styleUrls: ["./case360.component.scss"],
})
export class Case360Component implements OnInit, AfterViewInit {
  @ViewChild('tomailInput', { static: false }) tomailInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;
  selectedIndexTab: number = 0;
  UpdateSubscript: Subscription;
  searchParameterInfo: boolean = true;
  searchcaseText: any = "";
  searchDeployement: any;
  caseDataList = [];
  caseDataListBackup = {};
  caseDataListCard: unknown = [];
  Case360FormGroup: FormGroup = this._formBuilder.group({
    searchvalue: ["", Validators.required],
  });
  caseInfo: any;
  caseInfoDetails: any;
  requisitionNumberData: object = {};
  CaseList: any[] = [];
  showDetailsCase: boolean = false;
  patientHistory: any[] = [];
  encryptedurl: any = "";
  encryptflag: boolean = false;
  auditData: any;
  isEnablesso: any;
  encryptagentid: string = "";
  searchTerm: string[] = [];
  Filtervalue: string[] = [];
  deploymentItemsList = [];
  searchForm!: FormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  formValue: any;
  searchTermData: string[] = [];
  splitvalue: string[] = [];
  FormName: any;

  addOption: Filter;
  showForm = false;
  searchControl = new FormControl();
  submitted: any;
  responseData: any;
  control = new FormControl();
  //searchOptions:any
  DeploymentKey: string = "DeploymentKey";
  public reactiveFormdeployment: FormGroup;
  deploymentSelected: string = '';

  searchOptions: Filter[] = [];
  searchOptionslist: Filter[] = [];
  selectedDeploymentKey: any;
  searchFormKey: Filter[];
  searchfieldName: any[];
  filteredOptions: Observable<Filter[]> | undefined;
  keyword = 'name';
  oldFiltervalue: string[];
  oldSearchValue: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2,
    public service: CommonService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.Input != undefined && params.Input != "") {
        this.encryptedurl = params.Input;
        this.encryptflag = true;
        this.encryptagentid = params.EncryptID;
        this.service.updatessoStatus(true);
      }
    });
    this.reactiveFormdeployment = this._formBuilder.group({ deploymentname: ['', Validators.required] });
    this.searchForm = this._formBuilder.group({});
  }



  ngOnInit() {
    this.UpdateSubscript = this.service.UpdatedXmlData.subscribe((data) => {
      ;
      if (data !== "") {
        this.showCaseData(JSON.parse(data));
        this.selectedIndexTab = 4;
        setTimeout(() => {
          this.selectedIndexTab = 1;
        })
      }
    });

    this.getDeployments();

    this.getSearchOptions();
    if (
      this.encryptedurl !== "" &&
      this.encryptedurl != undefined &&
      this.encryptedurl !== "{}"
    ) {
      this.submitSearch();
    }

  }
  ngAfterViewInit() {
    if (this.tomailInput) {
      this.tomailInput.nativeElement.value = '';
    }
    this.setOverflowClass();
  }
  private waitForViewInit(): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      });
    });
  }

  getDeployments() {
    this.service.getDeployments("Deployments").subscribe(
      (data) => {
        if (data.length > 0) {
          this.deploymentItemsList = data;
        } else if (data.length === 0) {
          this.deploymentItemsList = [];
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  getSearchOptions() {
    this.service.getCase360Parameter().subscribe(
      (data: any[]) => {
        if (data.length > 0) {
          this.searchFormKey = data.map((item: any) => new Filter(item.lookupvalue));
          this.searchfieldName = data.map((item: any) => (item.lookupvalue.replace('#date#', '') + ':' + item.lookupid));
          this.searchOptions = data
            .filter(item => item.lookupvalue.toLowerCase() !== 'deploymentkey') // Filter out items with lookupvalue 'DeploymentKey'
            .map((item: any) => new Filter(item.lookupvalue.replace('#date#', '') + ':'));
          this.filteredOptions = this.searchControl.valueChanges
            .pipe(
              startWith(''),
              map(search => (search && typeof search === 'object') ? search.name : search),
              map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
            );
          this.searchOptionslist = JSON.parse(JSON.stringify(this.searchOptions));
          data.forEach((item: any) => {
            if (item.lookupvalue.trim().toLowerCase() !== 'deploymentkey') { // Check if lookupvalue is not 'DeploymentKey'
              const controlName = item.lookupvalue.replace('#date#', ''); // Get the lookupvalue as the control name
              this.searchForm.addControl(controlName, new FormControl('', Validators.required));
            }
          });
        } else {
          this.searchOptions = [];
          this.toastr.error("Failed to get search params", "", {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
          });
        }
      },
      (error) => {
        this.toastr.error("Something went wrong", "", {
          timeOut: 4000,
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  setOverflowClass() {
    let input: HTMLInputElement
    if (this.tomailInput) {
      input = this.tomailInput.nativeElement;
      input.addEventListener('input', () => {
        this.isInputOverflowed(input);
      });
    }
  }

  isInputOverflowed(input: HTMLInputElement): boolean {
    return input.scrollWidth > input.offsetWidth;
  }

  showCaseData(data) {
    ;
    this.ngxUiLoaderService.start();
    if (data !== "" && data !== undefined) {
     
      this.service.CaseDataDetails = data;
      this.caseInfo = JSON.parse(data.casedata);
      this.caseInfoDetails = this.caseInfo["Case"];
      this.caseInfoDetails['RequisitionNumber'] = data.ReqNumber;
      this.caseInfo = {
        ...this.caseInfo,
        DeploymentKey: data.deploymentKey,
      };
      this.auditData = {
        deploymentKey: data.deploymentKey,
        CaseGUID: this.caseInfoDetails.CaseGUID,
      };

      this.caseInfoDetails = {
        ...this.caseInfoDetails,
        DeploymentKey: data.deploymentKey,
      };
      if (this.caseInfoDetails.PatientHistoryDetails !== undefined &&
        Array.isArray(this.caseInfoDetails.PatientHistoryDetails.PatientHistory)
      ) {
        this.patientHistory =
          this.caseInfoDetails.PatientHistoryDetails.PatientHistory;
      } else if (this.caseInfoDetails.PatientHistoryDetails !== undefined) {
        this.patientHistory = [
          this.caseInfoDetails.PatientHistoryDetails.PatientHistory,
        ];
      } else {
        this.patientHistory = []
      }
      let obj = {
        ReqNumber: data.ReqNumber,
        DeploymentKey: data.deploymentKey,
        caseid: data.caseid,
        accessionnumber: data.caseInfoDetails.AccessionNumber,
        caseguid: data.caseInfoDetails.CaseGUID
        // "laborgid":data.laborgid,
        // "labaccountid":data.labaccountid
      };

      this.requisitionNumberData = obj;
      this.caseDataList;
      this.searchDeployement = data.deploymentKey;
    }
    setTimeout(()=>{
       this.showDetailsCase = true;
    },2000)
    //this.ngxUiLoaderService.stop();
  }

  getlabName(caseInfoDetails) {
    if (caseInfoDetails !== undefined) {
      if (Array.isArray(caseInfoDetails["ServiceProvider"])) {
        let labs = caseInfoDetails["ServiceProvider"].filter(
          (va) => va.Location.AccountType === "Laboratory"
        );
        if (labs.length > 0) {
          return labs[0].Location.DisplayName;
        }
      } else {
        if (
          caseInfoDetails["ServiceProvider"].Location.AccountType !==
          undefined &&
          caseInfoDetails["ServiceProvider"].Location.AccountType ===
          "Laboratory"
        ) {
          return caseInfoDetails["ServiceProvider"].Location.DisplayName;
        }
      }
    }
  }

  filter(name: string): Filter[] {
    return this.searchOptions.filter(option =>
      option.name.toLowerCase().includes(name.toLowerCase()));
  }


  removeSearchValue(value: any): void {
    if (value.includes(':')) {
      const TrimValue = value.trim();
      const index = this.Filtervalue.findIndex(term => term.trim().toLowerCase() === TrimValue.toLowerCase());
      if (index !== -1) {
        this.Filtervalue.splice(index, 1);
        this.splitvalue = value.split(":")
        this.FormName = this.splitvalue[0].trim();

        // Find the form control key in a case-insensitive manner
        //     const formKey = Object.keys(this.searchForm.controls)
        // .find(key => key.toLowerCase() === this.FormName.toLowerCase());

        this.searchForm.get(this.FormName).patchValue("");
        this.responseData = this.FormName + ':';
        this.addOption = { name: this.responseData };
        // Check if the option is already present in searchOptions
        const optionExists = this.searchOptions.some(option => option.name.toLowerCase() === this.addOption.name.toLowerCase());
        if (!optionExists) {
          this.searchOptions.push(this.addOption);
        }
      }
    }
    else {
      const index = this.searchTermData.indexOf(value);
      if (index !== -1) {
        this.searchTermData.splice(index, 1);
      }
    }
    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );
  }


  addtoFilterForm(event: MatChipInputEvent): void {
    if (event.value.trim() !== '') {
      const value = event.value.trim();
      const parts = value.split(':');
      if (parts.length > 1 && parts[1].trim() !== '') {
        const [inputKey, inputValue] = value.split(':').map(part => part.trim());
        if (!this.Filtervalue.includes(value.trim())) {
          this.Filtervalue.push(value.trim());
        }
        if (this.tomailInput) {
          this.tomailInput.nativeElement.value = null; // Display selected value in input field
        }
        // Find the form control key in a case-insensitive manner
        // const formKey = Object.keys(this.searchForm.controls)
        // .find(key => key.toLowerCase() === inputKey.toLowerCase());

        if (inputKey) {
          this.searchForm.get(inputKey).patchValue(inputValue); // Patch the form value
        }
        this.removeOptionWhenButtonCreated(inputKey);
      }
    }
  }
  selectedoption(event: MatAutocompleteSelectedEvent): void {
    this.tomailInput.nativeElement.value = event.option.value.name;
  }


  selectdeploymentEvent(item: any) {
    this.deploymentSelected = item;
  }

  onFocuseddeployment(event) {
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
  }

  cleareddeployment(event) {
    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
  }

  // toggleForm(){  
  //   this.showForm=!this.showForm;
  //   this.submitted=false;
  // }

  submitSearch() {
    let result: any;
    this.oldFiltervalue = this.Filtervalue;
    this.responseData = '';
    this.searchTerm = [];
    this.submitted = true;
    this.CaseList = [];
    this.showForm = false;
    if (this.menuTrigger && typeof this.menuTrigger.closeMenu === 'function') {
      this.menuTrigger.closeMenu();
    }
    //this.menuTrigger.closeMenu(); // Close the menu

    //3part search
    if (this.encryptflag) {
      if (
        this.encryptedurl !== "" &&
        this.encryptedurl != undefined &&
        this.encryptedurl !== "{}"
      ) {
        this.searchcaseText = this.encryptedurl;
        this.service.updatessoStatus(true);
        result = {
          LSearch: this.searchcaseText,
          Encrypt: this.encryptflag,
          AgentId: this.encryptagentid,
          FilterFields: []
        };
      } else if (this.searchcaseText !== "" && this.Case360FormGroup.valid) {
        let str = this.searchcaseText.split(":");
        // if (str.length < 0 ) {
        if (str.length < 3) {
          this.toastr.warning(
            "Invalid Search",
            "Search values should be Three parameters.",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
          return;
        } else if (str.length > 3) {
          this.toastr.warning(
            "Invalid Search",
            "Search values shouldn't be more than three parameters.",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
          return;
        } else if (str[str.length - 1] === "") {
          this.toastr.warning(
            "Invalid Search",
            "Search values should be  three parameters.",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
          return;
        }
      } else {
        this.toastr.warning("Required fields are empty", "Search", {
          positionClass: "toast-bottom-right",
          timeOut: 4000,
        });
        return;
      }
    }


    if (this.searchForm) {
      this.Filtervalue = this.ReadFromForm();
      this.searchTerm.forEach(searchTerm => {
        const [key, value] = searchTerm.split(':');
        const searchTermKey = key.trim();

        this.removeOptionWhenButtonCreated(key);
      });

      // Identify and handle missing values
      this.UpdateSerchOption(this.oldFiltervalue);

      if (this.deploymentSelected) {
        // Add the new element to searchTerm
        this.searchTerm.push(`DeploymentKey:${this.deploymentSelected}`);
      }
      this.searchTermData = this.searchTerm;

      let deploymentKeyExists = this.searchTerm.some(term => term.toLowerCase().includes('deploymentkey'));


      if (!this.encryptflag) {
        if (deploymentKeyExists && this.searchTerm != null && this.searchTerm.length > 1) {
          result = {
            LSearch: "",
            Encrypt: this.encryptflag,
            AgentId: "",
            FilterFields: []
          };

          this.searchFormKey.forEach((item: any, index: number) => {
            const [key, value] = this.searchfieldName[index].split(/:\s*|:\s*/);
            let FormKey = key.replace(/[\s#]/g, '');
            const searchTermItem = this.searchTerm.find(term => term.startsWith(key + ':'));
            if (searchTermItem) {
              const searchTermValue = searchTermItem.split(':')[1];
              result.FilterFields.push({
                [FormKey]: searchTermValue,
                fieldName: value
              });
            }
          });
        }
        else if (deploymentKeyExists == false) {
          // Warning for the case when searchTerm is not empty but deploymentKey is missing
          this.toastr.warning(
            "DeploymentKey is Mandatory",
            "",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
        }
        else {
          // Warning for the case when searchTerm is empty
          this.toastr.warning(
            "Search value is empty",
            "",
            {
              positionClass: "toast-bottom-right",
              timeOut: 4000,
            }
          );
        }
      }


    }
    if (result) {
      this.ngxUiLoaderService.start();
      this.service.Case360search(result).subscribe(
        (data) => {
          if (data.length > 0) {
            this.searchParameterInfo = false;
            data.forEach((element) => {
              if (element.casedata !== "") {
                element["caseInfoDetails"] = JSON.parse(element.casedata).Case;
              }
            });

            this.CaseList = data;
            if (data[0].ssoLaunch != undefined && data[0].ssoLaunch) {
              this.showCaseData(data[0]);
              var sessionObject1 = {
                userdata: {
                  FormattedDisplayName: data[0].userId,
                  userid: data[0].userId,
                },
              };
              sessionStorage.setItem(
                "sessionObjectlogin",
                JSON.stringify(sessionObject1)
              );
            }
            this.ngxUiLoaderService.stop();
          } else {
            this.searchParameterInfo = true;
            this.caseInfo = "";
            this.caseInfoDetails = "";
            this.requisitionNumberData = {};
            this.searchDeployement = "";
            this.ngxUiLoaderService.stop();
            this.toastr.warning(
              "Case data could not be found. Please try again with different input.",
              "",
              {
                positionClass: "toast-bottom-right",
                timeOut: 4000,
              }
            );
          }
        },
        (error) => {
          this.ngxUiLoaderService.stop();
          this.toastr.error(error.statusText, "Failed", {
            positionClass: "toast-bottom-right",
            timeOut: 4000,
          });
          this.searchDeployement = "";
        }
      );
    }

  }

  clearSearch() {

    this.tomailInput ? this.tomailInput.nativeElement.value = null : null;
    this.searchTermData = [];
    this.searchForm.reset();
    this.searchParameterInfo = true;
    this.caseInfoDetails = "";
    this.responseData = false;
    this.Filtervalue = [];
    this.CaseList = [];
    this.selectedDeploymentKey = [];
    this.searchTerm = [];

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptionslist.slice())
      );
    this.searchOptions = JSON.parse(JSON.stringify(this.searchOptionslist));

    this.deploymentSelected = '';
    this.reactiveFormdeployment.reset();
    this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
    this.reactiveFormdeployment.patchValue(this.deploymentItemsList);
  }

  clearForm() {

    this.tomailInput ? this.tomailInput.nativeElement.value = null : null;
    this.searchTermData = [];
    this.searchForm.reset();
    this.searchParameterInfo = true;
    this.caseInfoDetails = "";
    this.responseData = false;
    this.Filtervalue = [];
    //this.CaseList = [];
    // this.selectedDeploymentKey=[];
    this.searchTerm = [];

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptionslist.slice())
      );
    this.searchOptions = JSON.parse(JSON.stringify(this.searchOptionslist));

    //this.reactiveFormdeployment.patchValue({ "deploymentname": '' });
  }

  notContainsKey(value: string): boolean {
    let containsOption = false;
    const parts = value.split(':');
    // Check if any value from searchOptionslist is included in the value
    for (const option of this.searchOptionslist) {
      if (value.includes(option.name)) {
        //if (value.toLowerCase().includes(option.name.toLowerCase())) {
        containsOption = true;
        break;
      }
    }
    // Check if value does not include "DeploymentKey" or "Encrypt" and includes searchOptions
    return !value.includes("DeploymentKey") && containsOption && parts.length > 1 && parts[1].trim() !== '';
  }

  submitOnEnter(event: KeyboardEvent) {
    this.oldSearchValue = this.Filtervalue;
    this.Filtervalue = [];
    this.searchTerm = [];

    // Submit form when Enter key is pressed
    if (event.key === 'Enter') {

      if (this.searchForm) {

        this.Filtervalue = this.ReadFromForm();
        this.searchTerm.forEach(searchTerm => {
          const [key, value] = searchTerm.split(':');
          const searchTermKey = key.trim();

          // Split each option in searchOptions and compare keys
          this.searchOptions.forEach((option, index) => {
            const optionKey = option.name.split(':')[0].trim();
            const optionKeyTrimmed = optionKey.trim();
            if (optionKeyTrimmed === searchTermKey) {
              this.searchOptions.splice(index, 1); // Remove matching option
            }
          });
        });
        // Identify and handle missing values
        this.UpdateSerchOption(this.oldSearchValue);

      }


      this.filteredOptions = this.searchControl.valueChanges
        .pipe(
          startWith(''),
          map(search => (search && typeof search === 'object') ? search.name : search),
          map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
        );
    }

  }

  removeOptionWhenButtonCreated(value: string) {
    this.searchOptions.forEach((option, index) => {
      const optionKey = option.name.split(':')[0].trim();
      const optionKeyTrimmed = optionKey.trim();
      if (optionKeyTrimmed === value) {
        this.searchOptions.splice(index, 1); // Remove matching option
      }
    });

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(search => (search && typeof search === 'object') ? search.name : search),
        map((name: string) => name ? this.filter(name) : this.searchOptions.slice())
      );

  }

  ReadFromForm() {

    Object.keys(this.searchForm.controls).forEach((FormControlName) => {
      const formControl = this.searchForm.get(FormControlName);
      this.formValue = formControl ? formControl.value : null;

      if (this.formValue !== null && this.formValue !== '') {
        this.searchTerm.push(`${FormControlName}:${this.formValue}`);
      }
    });
    return this.searchTerm;
  }

  nodataalertflag: boolean = false;
  onTabChanged(value) {
    this.selectedIndexTab = value.index;
    if (this.selectedIndexTab === 4) {
      this.nodataalertflag = true;
    }
    else{
      this.nodataalertflag = false;
    }
  }
  ngOnDestroy() {
    this.UpdateSubscript.unsubscribe();
    this.service.CaseDataDetails = "";
    this.renderer.removeChild(document, this.el.nativeElement);// Cleanup: Remove the click event listener when the component is destroyed

  }

  UpdateSerchOption(oldSearchValue: string[]) {
    // Identify and handle missing values
    oldSearchValue.forEach(oldValue => {
      const [key, value] = oldValue.split(':');
      const formName = key.trim();
  
      // Check if the key exists in Filtervalue based on the key only (ignoring the value part)
      const isKeyPresent = this.Filtervalue.some(filterValue => {
        const [filterKey] = filterValue.split(':');
        return filterKey.trim() === formName;
      });
  
      // If the key is not present in Filtervalue and is not 'DeploymentKey', handle accordingly
      if (!isKeyPresent && formName && formName != 'DeploymentKey') {
        this.responseData = formName + ':'; 
        this.addOption = { name: this.responseData };
  
        // Check if the option is already present in searchOptions
        const optionExists = this.searchOptions.some(option => option.name.toLowerCase() === this.addOption.name.toLowerCase());
        
        if (!optionExists) {
          this.searchOptions.push(this.addOption);
        }
      }
    });
  }

   focusmatchipinput() {
    this.tomailInput.nativeElement.focus();
  }

}

interface CaseInfo {
  CaseType: string;
  Services: string;
  AccessionNumber: string;
}
